@font-face {
  font-family: "pionic";
  font-weight: 50;
  src: url("../assets/fonts/Cafe24Ssurround.ttf") format("truetype");
}

@font-face {
  font-family: "pionic_air";
  font-weight: 50;
  src: url("../assets/fonts/Cafe24SsurroundAir.ttf") format("truetype");
}

/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'pionic_air';
  font-weight: $font-weight-normal;
}

.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

body {
  font-size: 13px;
}

.descriptionBox img {
  width:100% !important;
  height:auto !important;
}


button{
  font-weight:bolder !important;
}

.auth_register_text:hover,.auth_findid:hover,.auth_findpwd:hover{
  color:#6a82fb;
}

.table_header_col_hidden{
  display: none;
}

.gallalyDesign {

  border:none !important;
  white-space: initial !important;
}

.gallalyDesign tbody {

  display: block;
  justify-content: space-around;

}

@media (hover: hover) { //pc
  .gallalyDesign tbody tr {
    display: inline-table;
    width:45%;
    height:340px;
    max-height: 340px;
    margin:2.5% !important;
    background-color: rgb(218, 227, 244);
    border-radius: 10px;
    
  }
}

@media (hover: none) { //모바일
  .gallalyDesign tbody tr {
    display: inline-table;
    width:100%;
    height:330px;
    margin:2.5% 0 !important;
    box-sizing: border-box;
    background-color: rgb(218, 227, 244);
    border-radius: 10px;
    
  }
}




.gallalyDesign tbody tr td {
  border-bottom:none !important;
  padding:20px 10px 10px 10px !important;
  
}

.bg-light{
  background-color:#fff !important;
}