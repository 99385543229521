.modal-header{
  padding: 0.7rem 1rem;
}

.pionicModalTable {
  padding:0;

  th {
    background-color: rgba(238, 238, 238, 0.3);
    // background-color: #efefef;
    
  }
  th,td{
    vertical-align: middle;
    border:1px solid #eee;
    padding:5px;
    height: 50px;
    font-weight: 500;
  }

  td {
    input {
      font-size: 1em;
    }

    input::placeholder{
      color:#ccc;
    }
  }
}

.pionicModalTableCenter {
  padding:0;

  th {
    
    background-color: rgba(238, 238, 238, 0.3);
    // background-color: #efefef;
    
  }
  th,td{
    text-align: center;
    vertical-align: middle;
    border:1px solid #eee;
    padding:5px;
    height: 50px;
    font-weight: 500;
  }

  td {
    input {
      font-size: 1em;
      text-align: center;
    }

    input::placeholder{
      color:#ccc;
    }
  }
}

.modalTable {
  border-collapse: collapse;
}

.react-bootstrap-table{
  max-height: 600px;
  overflow: auto;
  th {
    position: sticky;
    top: -2px;
    }
}

.BetteryTable{
  max-height: 400px;
  overflow: auto;
  padding:0;
  thead {
    position: sticky;
    top: -2px;
    }
  th {
  
    background-color: rgb(250, 250, 250);
    // background-color: #efefef;
    
  }
  th,td{
    text-align: center;
    vertical-align: middle;
    border:1px solid #eee;
    height: 50px;
    font-weight: 500;
  }

  td {
    input {
      font-size: 1em;
      text-align: center;
    }

    input::placeholder{
      color:#ccc;
    }
  }
}


.modalTable th {
  background-color: rgba(238, 238, 238, 0.3);
  // background-color: #efefef;
  
}

.modalTable th,.modalTable td {  
  vertical-align: middle;
  border:1px solid #eee;
  padding:5px;
  height: 50px;
  font-weight: 500;
}

.modalTable td input {
  font-size: 1em;
}

.modalTable td input::placeholder{
  color:#ccc;
}

.photoAddBtn {
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
}

.react-file-reader-button {
    position: absolute;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background:rgba(255,255,255,0.5);
    overflow: hidden;
}

.react-file-reader-button img {
  width:70%;
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);

}

.loading-container {
  border: 0px;
  background: rgba(0, 0, 0, 0);
  z-index: -1;
  content: none;
}
.loading-container > div.modal-content {
  border: 0px;
}
.overlay-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: rgba(255, 255, 255, 0);

  z-index: 1000;
}

.toast {
  min-width:200px !important;
}